import React, { Component } from 'react';
import styles from './ErrorBoundry.module.css';
import Button from '@mui/material/Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null, showDetails: false };
    this.gearRef = React.createRef(); // Create a ref for the falling gear
  }

  componentDidMount() {
   
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
    this.setState({ error, errorInfo });
    this.bounceBall();
  }

  toggleDetails = () => {
    this.setState((prevState) => ({ showDetails: !prevState.showDetails }));
  };

  bounce = (timeFraction) => {
    for (let a = 0, b = 1; ; a += b, b /= 2) {
      if (timeFraction >= (7 - 4 * a) / 11) {
        return -Math.pow((11 - 6 * a - 11 * timeFraction) / 4, 2) + Math.pow(b, 2);
      }
    }
  };

  easeOut = (timing) => {
    return (timeFraction) => {
      return 1 - timing(1 - timeFraction);
    };
  };

  bounceBall = () => {
    const bounceEaseOut = this.easeOut(this.bounce);
    const start = Date.now();
    const fallingGear = this.gearRef.current; // Use the ref to access the gear DOM element
    
    
    const animate = () => {
      let interval = (Date.now() - start) / 2000; // Animation duration: 2 seconds
      if (interval > 1) interval = 1;

      fallingGear.style.top = bounceEaseOut(interval) * 300 + 'px'; // Adjust Y-axis
      fallingGear.style.left = interval * 200 + 'px'; // Adjust X-axis

      if (interval < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ paddingTop: '25px' }}>
          <div className={styles.wrapper}>
            <div className={styles.gears} id="two-gears">
              <div className={styles.gearscontainer}>
                <div className={styles.gearrotate}></div>
                <div className={styles.gearrotateleft}></div>
                {/* Attach the ref to the falling gear */}
                <div ref={this.gearRef} className={`${styles.gearfall} circle`}></div>
              </div>
              <div className={styles.message}>
                <h1>Something went wrong.</h1>
                <Button onClick={this.toggleDetails} variant="outlined" style={{ marginTop: '15px' }}>
                  {this.state.showDetails ? 'Hide Technical Details' : 'Show Technical Details'}
                </Button>
              </div>
            </div>
          </div>

          {this.state.showDetails && (
            <div style={{ marginTop: '1rem', backgroundColor: '#f8d7da', padding: '1rem', borderRadius: '4px' }}>
              <h2>Error Details:</h2>
              <pre>{this.state.error?.toString()}</pre>
              <pre>{this.state.errorInfo?.componentStack}</pre>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
