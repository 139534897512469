import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

// MSAL configuration
const configuration = {
    auth: {
        clientId: import.meta.env.VITE_ENTRA_CLIENTID, // Client ID from .env
        authority: `https://login.microsoftonline.com/common`, // use common endpoint
        redirectUri: import.meta.env.VITE_ENTRA_REDIRECTURL
      },
      cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true // Set this to "true" if you're having issues on IE11 or Edge
      }
  };
  
  const pca = new PublicClientApplication(configuration);
  
  const initializeApp = async () => {
    await pca.initialize();
  };
  
  initializeApp().catch(error => {
    console.error('Error during app initialization', error);
  });
  
ReactDOM.createRoot(document.getElementById('root')).render(
    <MsalProvider instance={pca}>
        <BrowserRouter basename={import.meta.env.VITE_BASEURL}>
            <App />
        </BrowserRouter>
    </MsalProvider>,
)
