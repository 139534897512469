/* eslint-disable react/no-unknown-property */
import { useState, useContext } from 'react'
import styles from './NoAccountHeader.module.css'
import IconButton from '../IconButton/IconButton.jsx'
import Drawer from '@mui/material/Drawer';
import YellowButton from '../Buttons/YellowButton';
import { UserContext } from '../UserContext';
import { Link } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

export default function NoAccountImageHeader() {
    const { instance, accounts, inProgress } = useMsal();
    const [menuOpen, setMenuOpen] = useState(false);
    const { user } = useContext(UserContext);

    const request = {
        scopes: ["openid", "email"]
    }

    const handleLoginClick = async () => {
        await instance.loginRedirect(request);
    };

    return (<>
        <div componentname='NoAccountImageHeader' className='ContentContainer'>
            <div className='ContentArea' style={{height:'100%'} }>
               
                <div className={styles.ImageNavWrapper}>
                    <div className={styles.ImageNav}>
                        {/* <img className={styles.Logo} src={"/assets/images/intertek_logo_wyw.svg"} style={{ alignSelf: 'flex-start' }} /> */}
                        <div className={styles.ImageHeaderLinks}>

                            <a href="https://www.intertek.com/industries-services/">Industries & Services</a>
                            <a href="https://www.intertek.com/about/">About Us</a>
                            <a href="https://www.intertek.com/resources/">Resources</a>
                            <a href="https://www.intertek.com/contact/">Locations</a>

                        </div>
                        <div className={styles.ToggleMenu} style={{ marginLeft: 'auto' }}>
                            <IconButton style={{ alignSelf: 'flex-start' }} src={"/assets/images/burger_menu_light.svg"} onClick={() => setMenuOpen(true)}
                                mouseOver={"/assets/images/burger_menu_light.svg"} height={30} width={30} />
                        </div>
                    </div>
                </div>

                <div className={[styles.ImageHeaderText].join(' ')}>
                    <div className={styles.HeaderTextLarge} style={{marginTop:'70px'} }>
                    <img src='assets/images/logo/FastLaneLogo_ywy.svg' style={{height:'auto', width:'300px'}} />
                      
                        
                    </div>
                    {/* <div className={styles.HeaderTextSmall} style={{maxWidth:'50%', fontSize:'20pt' }}>
                        Speed. Success. Assured.
                    </div> */}

                    <div style={{ marginTop: '70px' }}>
                        {user === null ?
                            <YellowButton onClick={() => handleLoginClick()}>Login</YellowButton>    
                            :
                            <Link to="/portal" className="button-link">
                                <YellowButton>My Portal</YellowButton>
                            </Link>
                        }

                        
                    </div>
                </div>

            </div>

            
            
            <div className={styles.ImageContainer}>
               
            </div>
           
        </div>
        
        <Drawer
            anchor={'top'}
            open={menuOpen}
        >
            <div className={styles.Drawer}>
                <div className='ContentContainer'>
                    <div className={['ContentArea', styles.ImageNavWrapper].join(' ')} style={{display:'flex', justifyContent:'space-between', paddingBottom:'25px'} }>
                        <img className={styles.Logo} src={"/assets/images/intertek_logo_ywy.svg"} style={{ height: 36, width: 104, alignSelf: 'center' }} />
                        <img style={{ alignSelf: 'center', cursor: 'pointer', marginRight:'25px' }} src={"/assets/images/burger_menu_light.svg"} onClick={() => setMenuOpen(false)}
                            height={30} width={30} />
                       
                        
                    </div>
                </div>
               
                    <div  style={{width:'100%'} }>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/industries-services/">Industries & Services</a></div>
                        </div>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/about/">About Us</a></div>
                        </div>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/resources/">Resources</a></div>
                        </div>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/contact/">Locations</a></div>
                        </div>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/about/our-responsibility/">Responsibility</a></div>
                        </div>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/investors/">Investors</a></div>
                        </div>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/press/">Media</a></div>
                        </div>
                        <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}><a href="https://www.intertek.com/careers/">Careers</a></div>
                        </div>
                        {/* <div className={styles.MenuItem}>
                            <div style={{ marginLeft: '15px' }}>Worldwide</div>
                        </div> */}
                    </div>
                
            </div>
        </Drawer>

        
    </>
        )
}