import * as React from 'react';
import SlideInModal from '../SlideInModal/SlideInModal';
import YellowButton from '../Buttons/YellowButton';
import BlueButton from '../Buttons/BlueButton';
import OutlineButton from '../Buttons/OutlineButton';
import TextField from '@mui/material/TextField';
import styles from './AccountRequestDialog.module.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomerService from '../../services/CustomerService'; 

const helperTextStyle = {
    '& .MuiFormHelperText-root': {
        position: 'absolute',
        top: '35px'
    }
};

// props:
// onClose: function required
export default function AccountRequestDialog(props) {
    const [formModalSlideInOrOut, setFormModalSlideInOrOut] = React.useState('in'); // Manage slide in/out
    const [formModalSlideDirection, setFormModalSlideDirection] = React.useState('down'); // Manage slide in/out
    const [completedModalSlideInOrOut, setCompletedModalSlideInOrOut] = React.useState(''); // Manage slide in/out
    const [completedModalSlideDirection, setCompletedModalSlideDirection] = React.useState(''); // Manage slide in/out
    

    const { onClose } = props;
    const [email, setEmail] = React.useState(props.loginEmail);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [companyAddress, setCompanyAddress] = React.useState("");
    const [accountNumber, setAccountNumber] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");

    const [hasAccountNumber, setHasAccountNumber] = React.useState("");

    const [formErrors, setFormErrors] = React.useState({});
    const [errorMessage, setErrorMessage] = React.useState("");
    React.useEffect(() => {
        disableBodyScroll();
    }, []);

    // Function to disable scrolling
    function disableBodyScroll() {
        document.body.style.overflow = 'hidden';
    }

    // Function to enable scrolling
    function enableBodyScroll() {
        document.body.style.overflow = 'unset';
    }

    function handleClose() {
        enableBodyScroll();
        if (props.onClose !== undefined) {
            props.onClose();
        }
    }
    function validateAndSubmit() {
        let errors = {};
        let isValid = true;

        if (firstName.trim() === '') {
            isValid = false;
            errors.firstName = 'Required';
        }

        if (lastName.trim() === '') {
            isValid = false;
            errors.lastName = 'Required';
        }

        if (companyName.trim() === '') {
            isValid = false;
            errors.companyName = 'Required';
        }

        if (companyAddress.trim() === '') {
            isValid = false;
            errors.companyAddress = 'Required';
        }

        if (companyAddress.trim() === '') {
            isValid = false;
            errors.companyAddress = 'Required';
        }


        if (email.trim() === '') {
            isValid = false;
            errors.email = 'Required';
        }
        else if (!validateEmail(email)) {
            isValid = false;
            errors.email = 'Invalid Email';
        }

        if (phoneNumber.trim() === '') {
            isValid = false;
            errors.phoneNumber = 'Required';
        }

        if (hasAccountNumber === '') {
            isValid = false;
            errors.hasAccountNumber = 'Required';
        }

        if (hasAccountNumber === true && accountNumber === '') {
            isValid = false;
            errors.accountNumber = 'Required';
        }

        setFormErrors(errors);

        if (isValid) {
            setFormModalSlideInOrOut('out')
            setFormModalSlideDirection('left');

            setCompletedModalSlideInOrOut('in');
            setCompletedModalSlideDirection('right');

            let postData = {
                firstName: firstName,
                lastName: lastName,
                companyName: companyName,
                companyAddress: companyAddress,
                email: email,
                phoneNumber: phoneNumber,
                accountNumber: accountNumber
            }
           
             const response = CustomerService.send('POST', postData, 'Customer/requestAccount');
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function handleHasAccountNumberChanged(e) {
        setHasAccountNumber(e.target.value);
    }

    function handleLogoutRequested() {
        props.logoutRequested();
    }

    function resetForm() {
        setEmail('');
        setFirstName("");
        setLastName("");
        setCompanyName("");
        setCompanyAddress("");
        setAccountNumber("");
        setPhoneNumber("");
        setHasAccountNumber("");
    }

    return (<>
        <SlideInModal onClose={props.onClose} inOrOut={formModalSlideInOrOut} direction={formModalSlideDirection }>
            <div>

                <div style={{ width: '950px', maxWidth: '95vw', maxHeight: '95vh', overflowY: 'auto' }}>
                    <div style={{ backgroundColor: 'var(--intertek-yellow-1)', padding: '10px', display:'flex', justifyContent:'flex-end' }}>
                        <img src='/assets/images/intertek_logo_bb.svg' style={{ height: 36, width: 104, alignSelf: 'center' }} />
                    </div>
                    <div style={{ backgroundColor: 'var(--modal-body-light)', padding: '30px', fontFamily: 'Neo Sans' }}>
                        <div style={{ fontSize: 36, fontFamily: 'Neo Sans Light' }}>
                            Account Request
                        </div>
                        <div style={{ fontFamily: 'Neo Sans Light', padding: '15px', backgroundColor:'var(--intertek-grey-2)' }}>
                            Please complete the form below, and your Intertek sales representative will follow up to assist you. 
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap' }}>
                            <div className={styles.Column1 }>
                                <div className={styles.formElement }>
                                    <div>
                                        First Name
                                    </div>
                                    <div>
                                        <TextField sx={helperTextStyle} error={formErrors.firstName !== undefined} helperText={formErrors.firstName} size="small" placeholder='' style={{ width: '100%' }} value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                            
                                        />
                                    </div>
                                </div>
                                <div className={styles.formElement}>
                                    <div>
                                        Last Name
                                    </div>
                                    <div>
                                        <TextField sx={helperTextStyle} error={formErrors.lastName !== undefined} helperText={formErrors.lastName} size="small" placeholder='' style={{ width: '100%' }} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>
                                <div  className={styles.formElement }>
                                    <div>
                                       Company Name
                                    </div>
                                    <div>
                                        <TextField sx={helperTextStyle} error={formErrors.companyName !== undefined} helperText={formErrors.companyName} size="small" placeholder='Your Company' style={{ width: '100%' }} value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                    </div>
                                </div>
                                <div  className={styles.formElement }>
                                    <div>
                                        Company Address
                                    </div>
                                    <div>
                                        <TextField sx={helperTextStyle} error={formErrors.companyAddress !== undefined} helperText={formErrors.companyAddress} size="small" placeholder='Company Address' style={{ width: '100%' }} value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.Column2}>
                                <div className={styles.formElement}>
                                    <div>
                                        Email
                                    </div>
                                    <div>
                                        <TextField sx={helperTextStyle} error={formErrors.email !== undefined} helperText={formErrors.email} size="small" placeholder='email@example.com' autoComplete="email" style={{ width: '100%' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                                            {props.loginEmail != '' &&
                                                <div style={{position:'absolute'}}>Not the email you want? <span onClick={() => handleLogoutRequested()} style={{color:'var(--intertek-blue-1)',cursor:'pointer' }}>Logout</span></div>
                                            }
                                    </div>
                                </div>
                                <div  className={styles.formElement }>
                                    <div>
                                        Phone Number
                                    </div>
                                    <div>
                                        <TextField sx={helperTextStyle} error={formErrors.phoneNumber !== undefined} helperText={formErrors.phoneNumber} size="small" placeholder='###-###-####' style={{ width: '100%' }} value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                </div>
                                <div  className={styles.formElement }>
                                    <div>
                                        Do you already have an Intertek Customer Number?
                                    </div>
                                    <div>
                                   
                                        <Select
                                            error={formErrors.hasAccountNumber !== undefined}
                                            size='small'
                                            style={{width:'100%'} }
                                                value={hasAccountNumber}
                                                onChange={(e) => handleHasAccountNumberChanged(e)}
                                            >
                                                <MenuItem value={true}>Yes</MenuItem>
                                                <MenuItem value={false}>No</MenuItem>
                                            </Select>
                                   
                                    </div>
                                </div>
                                {hasAccountNumber &&

                                    <div className={styles.formElement}>
                                        <div>
                                            Customer Number
                                        </div>
                                        <div>
                                            <TextField sx={helperTextStyle} error={formErrors.accountNumber !== undefined} helperText={formErrors.accountNumber} size="small" placeholder='123456789' style={{ width: '100%' }} value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ height: '35px', color: 'var(--error-red)' }}>
                            {errorMessage}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '25px' }}>
                            <OutlineButton onClick={() => handleClose() }>Cancel</OutlineButton>
                            <BlueButton onClick={() => validateAndSubmit()}>Submit</BlueButton>
                        </div>
                    </div>

                </div>


                

            </div>
        </SlideInModal>

        {completedModalSlideDirection !== '' &&
            <SlideInModal onClose={props.onClose} inOrOut={completedModalSlideInOrOut} direction={completedModalSlideDirection} delay={.2}>
                <div>

                    <div style={{ width: '950px', maxWidth: '95vw', maxHeight: '95vh', overflowY: 'auto', height:'718px' }}>
                        <div style={{ backgroundColor: 'var(--intertek-yellow-1)', padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <img src='/assets/images/intertek_logo_bb.svg' style={{ height: 36, width: 104, alignSelf: 'center' }} />
                        </div>
                        <div style={{ backgroundColor: 'var(--modal-body-light)', padding: '30px', fontFamily: 'Neo Sans' }}>
                            <div style={{ fontSize: 36, fontFamily: 'Neo Sans Light' }}>
                               Thank you for your Account Request
                            </div>
                            <div style={{ fontFamily: 'Neo Sans Light', padding: '15px', backgroundColor: 'var(--intertek-grey-2)' }}>
                            We'll be in touch within 1-2 business days. For immediate assistance, please contact your Intertek sales representative.
                            </div>
                            
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '25px' }}>
                                <BlueButton onClick={() => handleClose()}>Close</BlueButton>
                            </div>
                        </div>

                    </div>




                </div>
            </SlideInModal>
        }

    </>


    );
}
