
import * as React from 'react';

import SlideInModal from '../SlideInModal/SlideInModal';
import YellowButton from '../Buttons/YellowButton';
import OutlineButton from '../Buttons/OutlineButton';
import BlueButton from '../Buttons/BlueButton';
import { UserContext } from '../UserContext';
import CustomerService from '../../services/CustomerService';
import IntertekLoading from '../Common/IntertekLoading';
import { useSnackbar } from 'notistack';

export default function CustomerVerificationDialog(props) {
    const [formModalSlideInOrOut, setFormModalSlideInOrOut] = React.useState('in'); // Manage slide in/out
    const [formModalSlideDirection, setFormModalSlideDirection] = React.useState('down'); // Manage slide in/out
    const [completedModalSlideInOrOut, setCompletedModalSlideInOrOut] = React.useState(''); // Manage slide in/out
    const [completedModalSlideDirection, setCompletedModalSlideDirection] = React.useState(''); // Manage slide in/out
    const [isLoading, setIsLoading] = React.useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [validationMessage, setValidationMessage] = React.useState('');

    React.useEffect(() => {
        const verifyCustomer = async () => {
            try {
                const response = await CustomerService.send('GET', '', 'Customer/VerifyEmail?token=' + props.verificationToken);
                setIsLoading(false);
                if (response.result == 'SUCCESS') {
                    setValidationMessage(<div style={{marginBottom:'25px'}}>
                        <div>Thank you for your interest in the Intertek FastLane quote portal.</div>
                        <div style={{marginTop:'10px'}}>Your email has been verified.</div>
                        <div style={{fontWeight:'bold', marginTop:'25px'}}>You can now login to your account.</div>
                    </div>);
                }
                else {
                    setValidationMessage(response.message);
                }
            } catch (error) {
                enqueueSnackbar('Error verifying customer', { variant: 'error' });
                setIsLoading(false);
            }
        }
        verifyCustomer();
    }, []);

    return (
        <SlideInModal onClose={props.onClose} inOrOut={formModalSlideInOrOut} direction={formModalSlideDirection} canDismiss={false}>
            <div componentname='CustomerVerificationDialog'>
                <div style={{ minHeight: '30vh', width: '40vw', minWidth: '300px', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--modal-body-light)' }}>
                    {isLoading ?
                        <div style={{ alignSelf: 'center' }}>
                            <IntertekLoading />
                            <div style={{ alignSelf: 'center' }}>
                                Verifying
                            </div>
                        </div>
                        :
                        <>
                            <div style={{ alignSelf: 'center', padding:'20px'  }}>
                                <div style={{ fontSize: '18px',  textAlign: 'center'}}>
                                    {validationMessage}
                                </div>
                                <div style={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
                                    <BlueButton onClick={props.onClose} style={{marginRight:'10px'}}>Close</BlueButton>
                                </div>
                            </div>
                        </>
                    }
                </div>


            </div>
        </SlideInModal>)
}